<template>
  <b-row>
    <b-col>
      <b-card title="Reportes" v-if="proccess.length">
        <b-row>
          <b-col>
            <input type="checkbox" v-model="show" />
            {{ show ? "Ocultar menú" : "Ver" }}
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" v-show="show">
            <VJstree
              id="admin-tree-information"
              ref="tree"
              multiple
              allow-batch
              draggable
              class="mt-5 mb-3 main-tree-default-vue"
              :data="proccess"
              show-checkbox
              @item-click="itemClick"
            >
              <!-- <template slot-scope="_">
            <div
              style="display: inherit; width: 200px"
              v-on:contextmenu="openMenu"
              v-if="_.model.slug == 'brigadas_disponibles_all'"
              @click.right="
                indexDataMenu = 0;
                current = _.model;
              "
            >
              <i
                :class="_.vm.themeIconClasses"
                role="presentation"
                v-if="!_.model.loading"
              ></i>
              <span v-html="_.model.text"></span>
              <button
                style="
                  border: 0px;
                  background-color: transparent;
                  cursor: pointer;
                "
                @click="customItemClick(_.vm, _.model, $event)"
              >
                <i class="fa fa-remove"></i>
              </button>
            </div>
          </template> -->
            </VJstree>
          </b-col>
          <b-col :md="!show ? 12 : 9" class="container-report p-3">
            <router-view></router-view>
          </b-col>
        </b-row>
      </b-card>
      <b-card title="Reportes" v-else>
          <b-row>
            <b-col class="text-center">
              <img src="assets/img/empty.png" alt="Empty" />
              <p>No tiene ningún permiso para reportes</p>
            </b-col>
          </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import VJstree from "vue-jstree";
import can from "@/permission";
import node from "./js/contain";
export default {
  components: {
    VJstree,
  },
  mounted() {
    this.proccess = [];
    node.map((el) => {
      if (this.can(el.permission)) {
        this.proccess.push(el);
      }
    });
  },
  data() {
    return {
      proccess: [],
      show: true,
    };
  },
  methods: {
    can: can,
    itemClick(node) {
      this.proccess.map((el) => {
        el.selected = false;
      });
      node.model.selected = true;
      if (this.$route.name != node.model.name) {
        this.$router.push({ name: node.model.name });
      }
    },
  },
};
</script>
<style>
.container-report {
  border: 1px solid;
  border-color: rgba(51, 51, 51, 0.4);
}
</style>
